import '@css/main.scss';
import '@js/bootstrap';
import { Loader } from '@js/utils/loader';
import {Filters} from "@js/components/Filters";
import {Slider} from "@js/components/Slider";
import {Count} from "@js/components/Count";
import {Header} from "@js/components/Header";

import AOS from 'aos';
import 'aos/dist/aos.css';

const scripts = [
  new Filters(),
  new Slider(),
  new Count(),
  new Header()
];

window.onload = () => {
    AOS.init({
        offset: 1,
    });

    // check if id is in url
    // then scroll to it minus header height
    // wait for page fully loaded
    if (window.location.hash) {
        const id = window.location.hash.substring(1);
        const element = document.getElementById(id);
        const headerHeight = document.querySelector('.header').offsetHeight;

        if (element) {
            window.scrollTo({
                top: element.offsetTop - headerHeight - 20,
                behavior: 'smooth'
            });
        }
    }

    new Loader().load(scripts)
}
